import { CommonMargin } from 'components/_layout/CommonMargin';
import { Section } from 'components/_layout/Section/Section';
import SEO from 'components/_layout/Seo';
import * as Common from 'components/_universal/Common';
import React from 'react';
import { OfferWithDescription, RelativeSection } from 'sections/Offer/Offer.styled';
import { deepTechData } from 'sections/Offer/offerData';
import { TakeThisStepSection } from 'sections/_universal/TakeThisStepSection';
import { TrustedBySection } from 'sections/_universal/TrustedBySection';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { OurOfferSection } from 'sections/_universal/OurOfferSection';
import styled from 'styled-components';

export default () => {
  const isMd = useBreakpoint('md');

  return (
    <>
      <SEO
        title="Deep Tech Solution: cutting-Edge Solutions for Your Business"
        meta={[
          {
            name: 'keywords',
            content: 'deep tech, deep tech companies, deep tech company, deep tech solutions',
          },
        ]}
        description={
          "Codahead offers deep tech solutions designed to meet the needs of tomorrow's businesses. Find out how we can help your company grow."
        }
      />

      <CommonMargin>
        <StyledSection title="Deep tech" variant="secondary" id="Deep tech" isMobile={isMd} withBreadCrumbs>
          <HeaderSection>
            Leverage AI (machine learning), blockchain, drones, objects and sounds identification or clean energy tech
            with us as we've developed and use these all.
          </HeaderSection>
        </StyledSection>
        <Section>
          <Common.Div flex={'column'} gap={isMd ? '0' : '140px'}>
            {deepTechData.map(({ imgSrc, title, desc, descSecondary, href, to }, index) => (
              <OfferWithDescription
                alignLeft={isMd || index % 2 === 0}
                imgSrc={imgSrc}
                title={title}
                desc={desc}
                href={href}
                descSecondary={descSecondary}
                isMd={isMd}
                key={title}
                to={to}
              />
            ))}
          </Common.Div>
        </Section>

        <OurOfferSection />
        <TakeThisStepSection />
        <TrustedBySection />
      </CommonMargin>
    </>
  );
};

const HeaderSection = styled.div`
  margin-top: 40px;
  max-width: 900px;
  font-size: 20px;
  line-height: 1.65;
  font-weight: 300;
`;

const StyledSection = styled(RelativeSection)`
  margin-top: 24px;
`;
